import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import { AccountBox, ContactPage, Dashboard, Home, Info, Person } from '@mui/icons-material';


type Anchor = 'left';

const SideBar = () => {
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        left: false,
    });

    const gotoHome = () => {
        navigate('/home');
    }

    const gotoCandidates = () => {
        navigate('/candidates');
    }

    const gotoAccounts = () => {
        navigate('/accounts');
    }

    const gotoContacts = () => {
        navigate('/contacts');
    }
    const gotoRequirements = () => {
        navigate('/requirements');
    }

    const gotoPipelines = () => {
        navigate('/pipelines');
    }

    const gotoActivities = () => {
        navigate('/activities');
    }
    const gotoDashboard = () => {
        navigate('/dashboard');
    }

    const gotoAssignments = () => {
        navigate('/assignments');
    }

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <List>
                <ListItem key="Home" disablePadding>
                    <ListItemButton onClick={gotoHome} >
                        <ListItemIcon>
                            <Home />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Dashboard" disablePadding>
                    <ListItemButton onClick={gotoDashboard} >
                        <ListItemIcon>
                            <Dashboard />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Candidates" disablePadding>
                    <ListItemButton onClick={gotoCandidates} >
                        <ListItemIcon>
                            <ContactPage />
                        </ListItemIcon>
                        <ListItemText primary="Candidates" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Accounts" disablePadding>
                    <ListItemButton onClick={gotoAccounts} >
                        <ListItemIcon>
                            <AccountBox />
                        </ListItemIcon>
                        <ListItemText primary="Accounts" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Contacts" disablePadding>
                    <ListItemButton onClick={gotoContacts} >
                        <ListItemIcon>
                            <ContactPage />
                        </ListItemIcon>
                        <ListItemText primary="Contacts" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Requirements" disablePadding>
                    <ListItemButton onClick={gotoRequirements} >
                        <ListItemIcon>
                            <Info />
                        </ListItemIcon>
                        <ListItemText primary="Requirements" />
                    </ListItemButton>
                </ListItem>

                <ListItem key="Pipelines" disablePadding>
                    <ListItemButton onClick={gotoPipelines} >
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Pipelines" />
                    </ListItemButton>
                </ListItem>

                <ListItem key="Activities" disablePadding>
                    <ListItemButton onClick={gotoActivities} >
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Activities" />
                    </ListItemButton>
                </ListItem>

                <ListItem key="Assignments" disablePadding>
                    <ListItemButton onClick={gotoAssignments} >
                        <ListItemIcon>
                            <Person />
                        </ListItemIcon>
                        <ListItemText primary="Assignments" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box >
    );

    return (
        <div>
            {(['left'] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button className="menu-bar" onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}


export default SideBar;