import { ThemeProvider } from '@emotion/react';
import { Container, createTheme, CssBaseline } from '@mui/material';
import { Outlet } from 'react-router-dom';
import '../styles/auth.scss';

const theme = createTheme();

const AuthLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" className='auth-container'>
          <CssBaseline />
          <Outlet />
      </Container>
    </ThemeProvider>
  );
}

export default AuthLayout;