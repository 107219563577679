import { lazy } from 'react';
import Loadable from '../components/Loadable';
import AuthLayout from '../components/AuthLayout';

const Login = Loadable(lazy(() => import('../pages/oauth/Login')));
const Register = Loadable(lazy(() => import('../pages/oauth/Register')));
const RegisterConfirmation = Loadable(lazy(() => import('../pages/oauth/RegisterConfirmation')));
const SetPasswordConfirmation = Loadable(lazy(() => import('../pages/oauth/SetPasswordConfirmation')));
const ForgotPassword = Loadable(lazy(() => import('../pages/oauth/ForgotPassword')));
const ForgotPasswordConfirmation = Loadable(lazy(() => import('../pages/oauth/ForgotPasswordConfirmation')));
const ResetPassword = Loadable(lazy(() => import('../pages/oauth/ResetPassword')));
const ResetPasswordConfirmation = Loadable(lazy(() => import('../pages/oauth/ResetPasswordConfirmation')));
const Logout = Loadable(lazy(() => import('../pages/oauth/Logout')));
const AuthRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: 'login',
            element: <Login />
        },
        {
            path: 'register',
            element: <Register />
        },
        {
            path: 'register-confirmation',
            element: <RegisterConfirmation />
        },
        {
            path: 'set-password',
            element: <SetPasswordConfirmation />
        },
        {
            path: 'forgot-password',
            element: <ForgotPassword />
        },
        {
            path: 'forgot-password-confirmation',
            element: <ForgotPasswordConfirmation />
        },
        {
            path: 'reset-password',
            element: <ResetPassword />
        },
        {
            path: 'reset-password-confirmation',
            element: <ResetPasswordConfirmation />
        },
        {
            path: 'logout',
            element: <Logout />
        }
    ]
};

export default AuthRoutes;