import { lazy } from 'react';
import Loadable from '../components/Loadable';
import ProfileLayout from '../components/ProfileLayout';


const Home = Loadable(lazy(() => import('../pages/profile/Home')));
const Profile = Loadable(lazy(() => import('../pages/profile/Profile')));
const ChangePassword = Loadable(lazy(() => import('../pages/profile/ChangePassword')));
const ChangePasswordConfirmation = Loadable(lazy(() => import('../pages/profile/ChangePasswordConfirmation')));
const Accounts = Loadable(lazy(() => import('../pages/account/Accounts')));
const Account = Loadable(lazy(() => import('../pages/account/Account')));
const Contacts = Loadable(lazy(() => import('../pages/contact/Contacts')));
const Contact = Loadable(lazy(() => import('../pages/contact/Contact')));
const Requirements = Loadable(lazy(() => import('../pages/requirement/Requirements')));
const Requirement = Loadable(lazy(() => import('../pages/requirement/Requirement')));
const Candidates = Loadable(lazy(() => import('../pages/candidate/Candidates')));
const Candidate = Loadable(lazy(() => import('../pages/candidate/Candidate')));
const Assignments = Loadable(lazy(() => import('../pages/assignment/Assignments')));
const Assignment = Loadable(lazy(() => import('../pages/assignment/Assignment')));
const Pipelines = Loadable(lazy(() => import('../pages/pipeline/Pipelines')));
const Pipeline = Loadable(lazy(() => import('../pages/pipeline/Pipeline')));
const Activities = Loadable(lazy(() => import('../pages/requirement/Activities')));
const Activity = Loadable(lazy(() => import('../pages/requirement/Activity')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));


const ProfileRoutes = {
    path: '/',
    element: <ProfileLayout />,
    children: [
        {
            path: 'home',
            element: <Home />
        },
        {
            path: '/profile',
            element: <Profile />
        },
        {
            path: 'change-password',
            element: <ChangePassword />
        },
        {
            path: 'change-password-confirmation',
            element: <ChangePasswordConfirmation />
        },
        {
            path: 'accounts',
            element: <Accounts />
        },
        {
            path: 'account',
            element: <Account />
        },

        {
            path: 'contacts',
            element: <Contacts />
        },
        {
            path: 'contact',
            element: <Contact />
        },
        {
            path: 'requirements',
            element: <Requirements />
        },

        {
            path: 'requirement',
            element: <Requirement />
        },
        {
            path: 'candidates',
            element: <Candidates />
        },
        {
            path: 'candidate',
            element: <Candidate />
        },
        {
            path: 'assignments',
            element: <Assignments />
        },
        {
            path: 'assignment',
            element: <Assignment />
        },
        {
            path: 'pipelines',
            element: <Pipelines />
        },
        {
            path: 'pipeline',
            element: <Pipeline />
        },
        {
            path: 'activities',
            element: <Activities/>
        },
        {
            path: 'activity',
            element: <Activity/>
        },
        {
            path: 'dashboard',
            element: <Dashboard/>
        },
    ]
};

export default ProfileRoutes;