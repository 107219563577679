import React from 'react';
import { Outlet } from 'react-router-dom';
import SideBar from '../pages/profile/SideBar';
import TopBanner from '../pages/profile/TopBanner';

const ProfileLayout = () => {
  return (
    <React.Fragment>
      <SideBar/>
      <TopBanner />
      <Outlet />
    </React.Fragment>
  );
}

export default ProfileLayout;